
































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { getLeaveReasons } from '@/admin/user';
import { downloadCsv } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

interface LeaveReasons {
  username: string;
  datetime: DisplayDate;
  reasons: string[];
  message: string;
}

export default defineComponent({
  name: 'Leave',
  components: {
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('leave'));

    const notification = useNotification();
    const leaveReasons: Ref<LeaveReasons[]> = ref([]);
    const headers = [
      { text: '退会日', value: 'datetime.date' },
      { text: 'ユーザーID', value: 'username' },
      { text: '退会理由', value: 'reasons' },
      { text: '退会理由（フリー）', value: 'message' },
    ];
    const isLoading = ref(true);

    getLeaveReasons()
      .then((result) => {
        leaveReasons.value = result.map((item) => ({
          username: item.username,
          datetime: new DisplayDate(item.datetime),
          reasons: item.reasons,
          message: item.message?.replace(/\r\n|\n|\r/gm, '<br>') || '',
        }));
        isLoading.value = false;
      })
      .catch((error) => {
        notification.error(error);
        isLoading.value = false;
      });

    // ダウンロードボタン
    const download = () => {
      downloadCsv(headers, leaveReasons.value, 'leaveReasons.csv');
    };

    return {
      pageTitle: '退会理由',
      myRoleSettings,
      leaveReasons,
      headers,
      isLoading,
      download,
    };
  },
});
